<script setup lang="ts">
import { nanoid } from 'nanoid'
import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useSwiper } from '/~/composables/base/use-swiper'
import { Tile } from '/~/composables/cms/use-cms'
import WidgetSlide from './bupa-home-banner-slide.vue'

const props = withDefaults(
  defineProps<{
    autoplay?: string
    title?: string
    enableTitle?: boolean
    responsiveSize?: boolean
    items?: Tile[]
  }>(),
  {
    items: () => [],
    title: '',
    enableTitle: false,
    responsiveSize: false,
  }
)

const swiperId = nanoid()
const enableSwipe = computed(() => props.items.length > 1)
const autoplayDelay = computed(() => Number(props.autoplay))

const {
  swiperContainer,
  initSwiper,
  destroySwiper,
  currentIndex,
  resetSwiper,
  selectSlide,
} = useSwiper(
  computed(() => ({
    autoplay: autoplayDelay.value
      ? {
          delay: autoplayDelay.value,
          disableOnInteraction: false,
        }
      : undefined,
    navigation: {
      nextEl: `[data-id="${swiperId}"][data-button="next"]`,
      prevEl: `[data-id="${swiperId}"][data-button="prev"]`,
      disabledClass: 'opacity-20 cursor-default',
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    spaceBetween: 5,
    slidesPerView: 1,
    slidesPerGroup: 1,
    allowTouchMove: enableSwipe.value,
  }))
)

watch(() => props.items, resetSwiper, { deep: true })

onMounted(initSwiper)

onBeforeUnmount(destroySwiper)
</script>

<template>
  <section
    class="relative w-full overflow-hidden rounded-lg"
    data-cy="widget-slider"
  >
    <div v-if="items" ref="swiperContainer" class="swiper-container">
      <h2
        v-if="enableTitle"
        class="mb-[30px] mt-2.5 text-xl font-bold leading-6"
      >
        {{ title }}
      </h2>
      <ul class="swiper-wrapper">
        <widget-slide
          v-for="(slide, index) in items"
          :key="index"
          ref="slide"
          v-bind="slide"
          :title-enabled="enableTitle"
          :responsive-size="responsiveSize"
        />
      </ul>
      <template v-if="enableSwipe">
        <div
          class="swiper-pagination absolute z-1 -mt-5 flex w-full items-center justify-center space-x-2.5"
        >
          <span
            v-for="(item, index) in items"
            :key="item.id"
            class="ease h-2.5 w-2.5 cursor-pointer rounded-md transition-width duration-200"
            :class="{
              'bg-white': index === currentIndex,
              'bg-strong opacity-50': index !== currentIndex,
            }"
            @click="selectSlide(index)"
          />
        </div>
        <button
          :data-id="swiperId"
          data-button="prev"
          tabindex="-1"
          class="absolute left-0 top-0 z-1 flex h-full w-11 items-center justify-center pl-[5px] text-eonx-neutral-600 transition duration-200"
        >
          <span
            class="-ml-11 flex items-center justify-center rounded-full bg-gray-900 p-[30px]"
          >
            <base-icon
              svg="plain/chevronleft"
              :size="16"
              class="absolute -mr-[15px] text-white"
            />
          </span>
        </button>
        <button
          :data-id="swiperId"
          data-button="next"
          tabindex="-1"
          class="absolute right-0 top-0 z-1 flex h-full w-11 items-center justify-center pr-2.5 text-eonx-neutral-600 transition duration-200"
        >
          <span
            class="-mr-11 flex items-center justify-center rounded-full bg-gray-900 p-[30px]"
          >
            <base-icon
              svg="plain/chevronright"
              :size="16"
              class="absolute -ml-[15px] text-white"
            />
          </span>
        </button>
      </template>
    </div>
  </section>
</template>
